import React from 'react';

import { t } from '@jotforminc/translation';
import BlackText from './black_text.svg';
import FridayText from './friday_text.svg';

import './v1.scss';

const BlackFridayBillingV1 = () => {
  return (
    <section className='blackfriday-2024-wrapper'>
      <div className='blackfriday-2024-container'>
        <div className="bag-wrapper wrapper-left">
          <img className="sale-bag" src="https://cdn.jotfor.ms/assets/img/campaigns/2024/blackfriday/img-left-bag.png" alt="Jotform Sale Bag" />
          <div className="shadow" />
        </div>
        <div className="billing--container">
          <div className="billing--title">
            <p className="billing--title-h2">
              {t('LIMITED TIME OFFER')}
            </p>
            <div className="title-banner">
              <div className="wrapper">
                <div className="svg1">
                  <BlackText />
                </div>
                <div className="svg2">
                  <FridayText />
                </div>
              </div>
            </div>
            <div className="title-percent">
              <span>{t('50% OFF')}</span>
              <span>
                {t('ANNUAL PLANS')}
                *
              </span>
            </div>
          </div>
        </div>
        <div className="bag-wrapper wrapper-right">
          <img className="sale-bag" src="https://cdn.jotfor.ms/assets/img/campaigns/2024/blackfriday/img-right-bag.png" alt="Jotform Sale Bag" />
          <div className="shadow" />
        </div>
      </div>
    </section>
  );
};

export default BlackFridayBillingV1;
